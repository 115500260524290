<template>
  <div class="flex items-center">
    <div class="flex flex-shrink-0 relative">
      <button
        type="button"
        @click="toggleExpansion"
        :class="`outline-none inline-flex items-center justify-center rounded-md text-white font-medium py-2 px-3 rounded-md
        focus:outline-none hover:bg-white hover:bg-opacity-40 ${buttonClasses}`"
      >
        <span>{{ item.label }}</span>
        <ChevronDownIcon class="h-4 ml-2" />
      </button>
      <div
        v-if="expanded"
        v-click-outside="closeExpansion"
        :class="`
          z-50
          origin-top-right
          absolute
          right-0
          mt-12
          w-48
          rounded-md
          shadow-lg
          py-1
          bg-white
          ring-1 ring-black ring-opacity-5
          ${expandedClasses}
        `"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu-button"
        tabindex="0"
        ref="expandedContent"
      >
        <template v-for="subItem in item.expansion" :key="subItem.label">
          <div class="py-2 px-4">
            <router-link
              v-if="subItem.routerLink"
              :to="subItem?.routerLink?.to"
              @click="expanded = false"
              class="block text-sm text-gray-700 font-semibold"
              role="menuitem"
              >{{ subItem.label }}</router-link
            >
            <div v-else>
              <button
                v-if="subItem.label == 'Sign out'"
                @click="onLogout"
                class="block text-sm text-gray-700 font-semibold outline-none"
                role="menuitem"
              >
                {{ subItem.label }}
              </button>
              <a
                class="block text-sm text-gray-700 font-semibold"
                v-if="subItem.label == 'Knowledge base'"
                @click="expanded = false"
                target="_blank"
                href="https://alfredsupport.freshdesk.com/support/solutions"
              >
                {{ subItem.label }}
              </a>
              <a
                class="block text-sm text-gray-700 font-semibold"
                v-if="subItem.label == 'API Documentation'"
                @click="expanded = false"
                target="_blank"
                href="https://docs.alfred.email/#api"
              >
                {{ subItem.label }}
              </a>
              <a
                class="block text-sm text-gray-700 font-semibold"
                v-if="subItem.label == 'Glossary'"
                @click="expanded = false"
                target="_blank"
                href="https://docs.alfred.email/#glossary"
              >
                {{ subItem.label }}
              </a>
            </div>
            <div v-if="subItem.details" class="text-xs text-gray-500">
              {{ subItem.details }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ChevronDownIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buttonClasses: {
      type: String,
      default: `p-2`,
    },
    expandedClasses: {
      type: String,
      default: ``,
    },
  },
  setup() {
    return { expanded: ref(false) };
  },
  methods: {
    toggleExpansion() {
      this.expanded = !this.expanded;
    },
    closeExpansion() {
      if (this.expanded) {
        this.expanded = false;
      }
    },
    async onLogout() {
      this.$store.dispatch("auth/LOGOUT");
    },
  },
};
</script>
