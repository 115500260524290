export const adminMenuItems = [
  {
    label: "Usage",
    routerLink: {
      to: {
        name: "UsageAdmin",
      },
    },
  },
  {
    label: "Global Defaults",
    routerLink: {
      to: {
        name: "GlobalDefaults",
      },
    },
  },
  {
    label: "Users",
    routerLink: {
      to: {
        name: "UsersAdminView",
      },
    },
  },
  {
    label: "Discount Codes",
    routerLink: {
      to: "/admin/discount-codes",
    },
  },
  {
    label: "Statistics",
    routerLink: {
      to: "/admin/user-statistics",
    },
  },
  {
    label: "Bouncer",
    routerLink: {
      to: "/admin/bouncer",
    },
  },
  {
    label: "Account",
    expansion: [
      {
        label: "Sign out",
      },
    ],
  },
];

export const userMenuItems = [
  {
    label: "Dashboard",
    routerLink: {
      to: "/",
    },
  },
  {
    label: "Credits",
    routerLink: {
      to: "/credits",
    },
  },
  {
    label: "API",
    routerLink: {
      to: "/api-keys",
    },
  },
  {
    label: "Support",
    expansion: [
      {
        label: "Knowledge base",
        // routerLink: {
        //   to: "/glossary",
        // },
        details: "Discover expert insights, step-by-step guides, and answers to your most pressing questions",
      },
      {
        label: "API Documentation",
        details: "Learn how to utilize Alfred programmatically",
      },
      {
        label: "Glossary",
        // routerLink: {
        //   to: "/glossary",
        // },
        details: "Get a complete understanding of what your results mean",
      },
      {
        label: "Contact",
        routerLink: {
          to: "/contact",
        },
        details: "Speak directly to a support or sales representative",
      },
    ],
  },
  {
    label: "Account",
    expansion: [
      {
        label: "Profile",
        routerLink: {
          to: "/account/profile",
        },
      },
      {
        label: "Users",
        routerLink: {
          to: "/account/users",
          },
      },
      {
        label: "Billing",
        routerLink: {
          to: "/account/billing",
        },
      },
      {
        label: "Usage",
        routerLink: {
          to: "/account/usage",
        },
      },
      {
        label: "Sign out",
      },
    ],
  },
];

export const subuserMenuItems = [
  {
    label: "Dashboard",
    routerLink: {
      to: "/",
    },
  },
  {
    label: "API",
    routerLink: {
      to: "/api-keys",
    },
  },
  {
    label: "Support",
    expansion: [
      {
        label: "Knowledge base",
        // routerLink: {
        //   to: "/glossary",
        // },
        details: "Discover expert insights, step-by-step guides, and answers to your most pressing questions",
      },
      {
        label: "API Documentation",
        details: "Learn how to utilize Alfred programmatically",
      },
      {
        label: "Glossary",
        // routerLink: {
        //   to: "/glossary",
        // },
        details: "Get a complete understanding of what your results mean",
      },
      {
        label: "Contact",
        routerLink: {
          to: "/contact",
        },
        details: "Speak directly to a support or sales representative",
      },
    ],
  },
  {
    label: "Account",
    expansion: [
      {
        label: "Profile",
        routerLink: {
          to: "/account/profile",
        },
      },
      {
        label: "Usage",
        routerLink: {
          to: "/account/usage",
        },
      },
      {
        label: "Sign out",
      },
    ],
  },
]
  export const BilleduserMenuItems =[
    {
      label: "Dashboard",
      routerLink: {
        to: "/",
      },
    },
    {
      label: "Credits",
      routerLink: {
        to: "/credits",
      },
    },
    {
      label: "API",
      routerLink: {
        to: "/api-keys",
      },
    },
    {
      label: "Support",
      expansion: [
        {
          label: "Knowledge base",
          // routerLink: {
          //   to: "/glossary",
          // },
          details: "Discover expert insights, step-by-step guides, and answers to your most pressing questions",
        },
        {
          label: "API Documentation",
          details: "Learn how to utilize Alfred programmatically",
        },
        {
          label: "Glossary",
          // routerLink: {
          //   to: "/glossary",
          // },
          details: "Get a complete understanding of what your results mean",
        },
        {
          label: "Contact",
          routerLink: {
            to: "/contact",
          },
          details: "Speak directly to a support or sales representative",
        },
      ],
    },
    {
      label: "Account",
      expansion: [
        {
          label: "Profile",
          routerLink: {
            to: "/account/profile",
          },
        },
        {
          label: "Billing",
          routerLink: {
            to: "/account/billing",
          },
        },
        {
          label: "Usage",
          routerLink: {
            to: "/account/usage",
          },
        },
        {
          label: "Sign out",
        },
      ],
    },
  ];
