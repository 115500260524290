<template>
  <div>
    <div :class="margin">
      <UsageFilters @onFilter="onFilter" :singleUser="singleUser" />
    </div>

    <div
      :class="`overflow-x-auto rounded-md ${isStaff ? 'mb-8' : 'mb-6'} ${
        noShadow ? '' : 'shadow'
      }`"
    >
      <div class="align-middle bg-white inline-block w-full">
        <table class="divide-gray-300 w-full text-left table-auto">
          <thead class="border-b-2">
            <tr>
              <th
                v-for="columnName in columnNames"
                :key="columnName.name"
                scope="col"
                :class="`
                px-6
                py-5
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
                ${columnName.name === 'Date' ? 'w-1/4' : ''}
              `"
              >
                <template v-if="columnName.name !== 'Date' && !real_time"
                  >
                  <FilterableHeaderCell
                    :title="columnName.name"
                    :param="columnName.param"
                    @switchOrder="onSwitchOrders(columnName.param)"
                  />
                </template>
                <template v-else-if="columnName.name == 'Service'">
                {{ columnName.name }}
                </template>
                <template v-else-if="columnName.name !== 'Date' && real_time"
                  >
                  <FilterableHeaderCell
                    :title="columnName.name"
                    :param="columnName.param"
                    @switchOrder="onSwitchOrders(columnName.param)"
                  />
                </template>
                <template v-else>
                  <FilterableHeaderCell
                    ref="dateFilter"
                    :title="columnName.name"
                    :param="'created_at'"
                    @switchOrder="onSwitchOrder()"
                  />
                </template>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="data?.length">
              <tr v-for="(row, index) in data" :key="index">
                <td v-if="!real_time" class="px-6 py-5 text-sm text-gray-500 truncate" :title="row?.input_file?.name">
                  {{ truncateText(row?.input_file?.name, 20) }}
                </td>

                <td
                  class="px-6 py-5 text-sm text-gray-500"
                  style="width: 200px"
                >
                  {{ serviceMappings[displayActions(row.actions)] }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ row.created_with }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ formatCount(row.emails_count) }}
                </td>   
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ formatCount(row.credit_cost) }}
                </td>               
                <td v-if="!singleUser" class="px-6 py-5 text-sm text-gray-500">
                  {{ row.created_by }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{
                    row?.created_at?.length === 10
                      ? new Date(row.created_at).toLocaleDateString("en-US")
                      : new Date(row.created_at).toLocaleString("en-US")
                  }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500" v-if="isStaff">
                  <button
                    type="button"
                    class="rounded-md text-gray-700 bg-white border border-gray-300 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium m-0 w-auto cursor-pointer"
                    v-if="
                      displayActions(row.actions) != 'real_time'
                      && displayActions(row.actions) != 'evaluation'
                    "
                    @click="details(row.id)"
                  >
                    View details
                  </button>

                  <button
                    type="button"
                    class="rounded-md text-gray-700 bg-white border border-gray-300 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium m-0 w-auto cursor-pointer"
                    v-else-if="displayActions(row.actions) == 'evaluation'"
                    @click="showEvaluation(row.id)"
                  >
                    View details
                  </button>
                </td>
              </tr>
            </template>
            <template v-else>
              <div class="px-6 py-5 text-sm text-gray-500 text-left">
                No data
              </div>
            </template>
          </tbody>
        </table>
      </div>
      <Paginator
        ref="paginator"
        :onPageChange="onPageChange"
        :pageSize="15"
        :data="paginationData"
      />
    </div>
  </div>
</template>
<script>
import { ref, reactive } from "vue";
import Paginator from "@/components/common/Paginator";
import UsageFilters from "@/components/common/usage-page/UsageFilters";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import { mapGetters } from "vuex";
import eventBus from "@/utils/eventBus";
import humanize from "humanize";
export default {
  name: "UsageTable",
  components: {
    Paginator,
    UsageFilters,
    FilterableHeaderCell,
  },
  props: {
    data: {
      type: Array,
    },
    paginationData: {
      type: Object,
    },
    singleUser: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.$refs.dateFilter.setOrdering) {
      this.$refs.dateFilter.setOrdering("-created_at");
    }
    if (this.singleUser) {
      this.filterData.ordering = "-created_at";
      this.columnNames.splice(5, 1);
    }
  },
  setup() {
    const columnNames = ref([
      { name: "Name", param: "input_file__name" },
      { name: "Service", param: "actions" },
      { name: "Type", param: "created_with" },
      { name: "Quantity", param: "emails_count" },
      { name: "Credit", param: "credit_cost" },
      { name: "User", param: "created_by" },
      { name: "Date", param: "created_at" },
    ]);
    const filterData = reactive({
      page: 1,
      ordering: "-created_at",
      action: "",
      selected_user: "",
      created_with: "",
      size: 15,
    });
    const real_time = ref(false)
    const serviceMappings = {
      evaluation: "Evaluation",
      threat_evaluation: "Threat Evaluation",
      verification: "Deliverability + Threat Detection",
      deliverability: "Deliverability Check",
      threat_assessment: "Threat Detection",
      real_time: "Real Time"
    };
    return { columnNames, filterData, real_time, serviceMappings };
  },
  computed: {
    ...mapGetters("auth", ["isStaff"]),
    noShadow() {
      return this.isStaff && this.singleUser;
    },
    ...mapGetters({ settings: "settings/settings" }),
    jobs() {
      return this.processJobs(this.$props.data);
    },
  },
  beforeMount(){
    if(this.singleUser){
      delete this.serviceMappings.threat_evaluation;
    }
  },
  methods: {
    truncateText(text, length) {
      if (text?.length > length) {
        return text.slice(0, length) + '...';
      }
      return text;
    },
    async details(id) {
      await this.$router.push({
        name: "UsageViewDetails",
        params: { id: id },
      });
    },

    displayActions(actions) {
      if (actions) {
        if (actions.length === 1) {
          return actions[0];
        }
        return `${actions[0]}, ${actions[1]}`;
      } else {
        return "real_time";
      }
    },
    formatCount(count) {
      return humanize.numberFormat(count, 0);
    },
    async onPageChange(page) {
      this.filterData.page = page;
      this.$emit("stepper", {
        myPage: page,
      });
      eventBus.trigger("update-usage", this.filterData);
    },
    async onSwitchOrder() {
      let order =
        this.filterData.ordering == "created_at" ? "-created_at" : "created_at";
      this.filterData.ordering = order;
      eventBus.trigger("update-usage", this.filterData);
      await this.$refs.paginator.setPage(1);
    },
    async onSwitchOrders(name) {
      let order =
        this.filterData.ordering == name ? `-${name}` : name;
      this.filterData.ordering = order;
      eventBus.trigger("update-usage", this.filterData);
      await this.$refs.paginator.setPage(1);
    },
    async onFilter({ service, type, search, emailJob, users }) {
      this.filterData = {
        ...this.filterData,
        action: service,
        created_with: type,
        selected_user: users,
        search,
        emailJob,
      };
      if (emailJob === 'Real-time' && this.singleUser) {
        this.real_time = true;
        this.filterData.ordering = "-created_at";
        this.columnNames = ref([
      { name: "Service", param: "actions" },
      { name: "Type", param: "created_with" },
      { name: "Quantity", param: "emails_count" },
      { name: "Credit", param: "credit_cost" },
      { name: "Date", param: "created_at" },
    ]);
      }else if(emailJob != 'Real-time' && this.singleUser){
        this.real_time = false;
        this.columnNames = ref([
      { name: "Name", param: "input_file__name" },
      { name: "Service", param: "actions" },
      { name: "Type", param: "created_with" },
      { name: "Quantity", param: "emails_count" },
      { name: "Credit", param: "credit_cost" },
      { name: "Date", param: "created_at" },
    ]);
      }else if(emailJob === 'Real-time' && !this.singleUser){
        this.real_time = true;
        this.columnNames = ref([
      { name: "Service", param: "actions" },
      { name: "Type", param: "created_with" },
      { name: "Quantity", param: "emails_count" },
      { name: "Credit", param: "credit_cost" },
      { name: "User", param: "created_by" },
      { name: "Date", param: "created_at" },
    ]);
      }else if(emailJob != 'Real-time' && !this.singleUser){
        this.real_time = false;
        this.columnNames = ref([
      { name: "Name", param: "input_file__name" },
      { name: "Service", param: "actions" },
      { name: "Type", param: "created_with" },
      { name: "Quantity", param: "emails_count" },
      { name: "Credit", param: "credit_cost" },
      { name: "User", param: "created_by" },
      { name: "Date", param: "created_at" },
    ]);
      }
      eventBus.trigger("update-usage", this.filterData);
      await this.$refs.paginator.setPage(1);
    },
    async showEvaluation(id){
      const jobDetails = await this.$store.dispatch("fetching/FETCH", {
      apiCall: async () =>
        await this.$alfredService.getJob(id),
    });
      jobDetails["name"] = jobDetails.input_file.name;
      jobDetails["isAdminView"] = true;
      eventBus.trigger("open-details", jobDetails);
    }
  },
};
</script>
